<template>
  <div>
    <h2>Today's Orders</h2>
    <p>Last updated: {{ last_updated }}</p>
    <table border="1" v-if="orders.length > 0">
      <thead>
        <tr>
          <th>Symbol</th>
          <th>Quantity</th>
          <th>Price</th>
          <th>Action</th>
          <th>Type</th>
          <th>Time</th>
          <th>Order ID</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in orders" :key="order.orderId">
          <td>{{ order.symbol }}</td>
          <td>{{ order.filledQuantity }}</td>
          <td>{{ order.executedPrice }}</td>
          <td>{{ order.orderAction }}</td>
          <td>{{ order.priceType }}</td>
          <td>{{ order.executedTime }}</td>
          <td>{{ order.orderId }}</td>
        </tr>
      </tbody>
    </table>
    <p v-else>No executed orders.</p>
  </div>
</template>

<script>
export default {
  name: 'Orders',
  props: {
    performance: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      alerts: {}
    };
  },

  computed: {
    orders() {
      const orders = [];
      // Check if performance and performance.symbols exist
      if (this.performance && this.performance.symbols) {
        for (const symbolObj of Object.values(this.performance.symbols)) {
          if (symbolObj && Array.isArray(symbolObj.orders)) {
            for (const order of symbolObj.orders) {
              const new_order = {
                symbol: symbolObj.symbol,
                filledQuantity: order.filledQuantity,
                executedPrice: order.executedPrice,
                orderAction: order.orderAction,
                priceType: order.priceType,
                executedTime: order.executedTime.split(' ')[1],
                orderId: order.orderId,
              };
              orders.push(new_order);
            }
          }
        }
      }
      return orders;
    },
    last_updated() {
      return this.performance && this.performance.last_updated ? this.performance.last_updated : '';
    },
  },

  methods: {}
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: right;
}

th:first-child,
td:first-child {
  text-align: left;
  width: auto;
}
</style>
