<template>
    <div>
      <div class="header">
        <h2 class="title">Date Range Performance: {{ formattedStartDate }} - {{ formattedEndDate }}</h2>
        <p class="title" v-if="lastUpdatedTime">Last updated: {{ lastUpdatedDate }} {{ lastUpdatedTime }}</p>
      </div>
      <button class="print-button no-print" @click="printPage">🖨 Print</button>
      
      <!-- Loading State -->
      <div v-if="loading" class="loading-container">
        <p>Loading...</p>
      </div>
  
      <!-- Performance Table -->
      <table v-else border="1">
        <thead>
          <tr>
            <th>Symbol</th>
            <th v-for="account in accounts" :key="account.account_id">{{ account.account_id }}</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody v-if="symbols.length > 0">
          <tr v-for="symbol in symbols" :key="symbol">
            <td>{{ symbol }}</td>
            <td v-for="account in accounts" :key="account.account_id">
              {{ account.symbolData[symbol]?.pnl ?? 0 }}
            </td>
            <td>{{ rowTotals[symbol] }}</td>
          </tr>
          <tr>
            <td><strong>Total</strong></td>
            <td v-for="account in accounts" :key="account.account_id">{{ columnTotals[account.account_id] }}</td>
            <td>{{ grandTotal }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="3">No performance data available</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    name: "DateRangePerformance",
    props: {
      start_date: {
        type: String, // MMDDYYYY
        required: true,
      },
      end_date: {
        type: String, // MMDDYYYY
        required: true,
      },
      apiEndpoint: {
        type: String,
        default: "/api/strats/performance",
      }
    },
    data() {
      return {
        performances: [],
        accounts: [],
        symbols: [],
        rowTotals: {},
        columnTotals: {},
        grandTotal: 0,
        lastUpdatedTime: null,
        lastUpdatedDate: null,
        maxLastUpdatedStr: null,
        loading: false,
      };
    },
    watch: {
      start_date: {
        immediate: true,
        handler() {
          this.fetchAndAggregateData();
        }
      },
      end_date: {
        immediate: true,
        handler() {
          this.fetchAndAggregateData();
        }
      }
    },
    computed: {
      formattedStartDate() {
        return this.formatDate(this.start_date);
      },
      formattedEndDate() {
        return this.formatDate(this.end_date);
      },
    },
    methods: {
      async fetchAndAggregateData() {
        if (!this.start_date || !this.end_date) {
          this.resetData();
          return;
        }
  
        this.loading = true;
  
        const start = this.parseDate(this.start_date);
        const end = this.parseDate(this.end_date);
  
        if (end < start) {
          this.resetData();
          this.loading = false;
          return;
        }
  
        const queryParams = new URLSearchParams({ 
          startDate: this.start_date,
          endDate: this.end_date
        }).toString();
  
        try {
          const res = await fetch(`${this.apiEndpoint}?${queryParams}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'same-origin',
          });
  
          if (!res.ok) {
            console.error(`HTTP error! status: ${res.status} for date range`);
            this.resetData();
            this.loading = false;
            return;
          }
  
          const json = await res.json();
          // Convert aggregated object into array format
          const arrayPerformances = Object.entries(json).map(([account_id, value]) => ({
            account_id,
            symbols: value.symbols || [],
            last_updated: value.last_updated
          }));
  
          this.performances = arrayPerformances;
          this.prepareData();
        } catch (error) {
          console.error('API request error:', error);
          this.resetData();
        }
  
        this.loading = false;
      },
  
      prepareData() {
        if (!this.performances || this.performances.length === 0) {
          this.resetData();
          return;
        }
  
        const accountsData = {};
        const symbolsSet = new Set();
  
        this.maxLastUpdatedStr = null;
  
        for (const perf of this.performances) {
          const accountId = perf.account_id;
          if (!accountsData[accountId]) {
            accountsData[accountId] = { account_id: accountId, symbolData: {} };
          }
          (perf.symbols || []).forEach(sym => {
            if (sym && sym.symbol && typeof sym.pnl === "number") {
              const existing = accountsData[accountId].symbolData[sym.symbol]?.pnl || 0;
              accountsData[accountId].symbolData[sym.symbol] = { pnl: parseFloat((existing + sym.pnl).toFixed(2)) };
              symbolsSet.add(sym.symbol);
            }
          });
  
          if (perf.last_updated) {
            if (!this.maxLastUpdatedStr || perf.last_updated > this.maxLastUpdatedStr) {
              this.maxLastUpdatedStr = perf.last_updated;
            }
          }
        }
  
        if (this.maxLastUpdatedStr) {
          const [isoDatePart, timePart, tz] = this.maxLastUpdatedStr.split(" ");
          this.lastUpdatedDate = this.convertISODateToMMDDYYYY(isoDatePart);
          this.lastUpdatedTime = `${timePart} ${tz}`;
        }
  
        const accounts = Object.values(accountsData);
        const rowTotals = {};
        const columnTotals = {};
        let grandTotal = 0;
  
        accounts.forEach((account) => {
          let accountTotal = 0;
          Object.entries(account.symbolData).forEach(([symbol, data]) => {
            const pnl = data.pnl || 0;
            rowTotals[symbol] = (rowTotals[symbol] || 0) + pnl;
            accountTotal += pnl;
          });
          columnTotals[account.account_id] = parseFloat(accountTotal.toFixed(2));
          grandTotal += accountTotal;
        });
  
        Object.keys(rowTotals).forEach(key => rowTotals[key] = parseFloat(rowTotals[key].toFixed(2)));
        grandTotal = parseFloat(grandTotal.toFixed(2));
  
        this.accounts = accounts;
        this.symbols = Array.from(symbolsSet).sort();
        this.rowTotals = rowTotals;
        this.columnTotals = columnTotals;
        this.grandTotal = grandTotal;
      },
  
      parseDate(dateStr) {
        const mm = parseInt(dateStr.slice(0, 2), 10);
        const dd = parseInt(dateStr.slice(2, 4), 10);
        const yyyy = parseInt(dateStr.slice(4, 8), 10);
        return new Date(yyyy, mm - 1, dd);
      },
  
      formatDate(dateStr) {
        const match = dateStr.match(/(\d{2})(\d{2})(\d{4})/);
        return match ? `${match[1]}/${match[2]}/${match[3]}` : "";
      },
  
      formatDateForAPI(date) {
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        return `${mm}${dd}${yyyy}`;
      },
  
      convertISODateToMMDDYYYY(isoDate) {
        // isoDate is 'YYYY-MM-DD'
        const [yyyy, mm, dd] = isoDate.split('-');
        return `${mm}/${dd}/${yyyy}`;
      },
  
      resetData() {
        this.accounts = [];
        this.symbols = [];
        this.rowTotals = {};
        this.columnTotals = {};
        this.grandTotal = 0;
        this.lastUpdatedTime = null;
        this.lastUpdatedDate = null;
        this.maxLastUpdatedStr = null;
      },
  
      printPage() {
        window.print();
      },
    },
  };
  </script>
  
  <style scoped>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }

  .title {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th,
  td {
    border: 1px solid #ddd;
    padding: 5px;
    text-align: right;
  }
  
  th:first-child,
  td:first-child {
    text-align: left;
  }
  
  /* Simple styling for loading */
  .loading-container {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
  }

.print-button {
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  color: black;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -webkit-appearance: none; /* Removes default styling on iOS */
  -moz-appearance: none;    /* Removes default styling on Firefox */
  appearance: none;         /* Removes default styling */
  font-family: 'Montserrat', sans-serif;
}
  
  /* This class hides elements when printing */
  .no-print {
    margin-bottom: 20px;
  }
  
  @media print {
    .no-print {
      display: none !important;
    }
  
    @page {
      size: A4 landscape;
      margin: 1cm;
    }
  
    table {
      font-size: 12px;
    }
  }
  </style>
  