<template>
  <main class="main-container">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
    <div class="header">
      <h1>Executive Summary</h1>
      <div class="account">
        <ETradeDropdown 
          :selected_account_id="selected_account_id"
          :account_ids="account_ids"
          @update:selected_account_id="handleAccountIdUpdate"
        />
      </div>
    </div>
    <router-link to="/performance">Go to Performance Page</router-link>

    <div class="main">
      <div>
        <h2>Active Strategies</h2>
        <div class="cards-container" v-if="filteredActives.length > 0">
          <ActiveStrat v-for="active in filteredActives" :key="active.id" @update="refresh"
          :strat="active" />
        </div>
        <div v-else>None</div>
        
        <h2>Inactive Strategies</h2>
        <div class="cards-container" v-if="filteredInactives.length > 0">
          <InactiveStrat v-for="inactive in filteredInactives" :key="inactive.id" @update="refresh"
            :strat="inactive"
            :account_id="selected_account_id" />
        </div>
        <div v-else>None</div>
      </div>
      <div>
        <div class="portfolio-container">
          <Portfolio :portfolio="curr_portfolio" :last_updated="last_updated_portfolio"/>
        </div>
        <div class="orders-container">
          <Orders :performance="curr_performance"/>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ActiveStrat from '@/components/Summary/ActiveStrat.vue';
import InactiveStrat from '@/components/Summary/InactiveStrat.vue';
import ETradeDropdown from '../common/ETradeDropdown.vue';
import Portfolio from './Portfolio.vue';
import Orders from './Orders.vue';

export default {
  name: 'SummaryPage',
  components: { ActiveStrat, InactiveStrat, ETradeDropdown, Portfolio, Orders },

  data() {
    return {
      strats: [],
      account_ids: [
        { value: '9365', text: 'x9365' },
        { value: '9126', text: 'x9126' },
        { value: '9066', text: 'x9066' },
        { value: '9776', text: 'x9776' },
        { value: '9206', text: 'x9206' },
      ],
      selected_account_id: '9365',
      portfolios: {},
      last_updated_portfolio: null,
      performances: {},
      start_date: '', // MMDDYYYY for performance fetching
    }
  },

  created() {
    if (!this.$store.state.username) {
      this.$router.push({ name: 'Login' });
    }
    this.setDefaultStartDate();
    this.refresh();
  },

  computed: {
    filteredActives() {
      return this.strats.filter(strat => strat.account_ids.includes(this.selected_account_id));
    },
    filteredInactives() {
      return this.strats.filter(strat => !strat.account_ids.includes(this.selected_account_id));
    },
    curr_portfolio() {
      return this.portfolios[this.selected_account_id] || {};
    },
    curr_performance() {
      return this.performances[this.selected_account_id] || {};
    },
    performance_list() {
      return Object.entries(this.performances).map(([key, value]) => ({ account_id: key, ...value }));
    },
  },

  methods: {
    async refresh() {
      try {
        this.getStrats();
        this.getPortfolios();
        this.getPerformances();
      } catch (error) {
        console.error('Error in refresh:', error);
      }
    },

    getStrats() {
      fetch('/api/strats', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin'
      })
      .then(res => res.json())
      .then(res => { this.strats = res; })
      .catch(error => {
        console.error('API request error:', error);
        throw error;
      });
    },

    getPortfolios() {
      fetch('/api/strats/portfolios', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin'
      })
      .then(res => res.json())
      .then(res => { 
        this.portfolios = Object.fromEntries(Object.entries(res).map(([key, value]) => [String(key), value]));
        this.last_updated_portfolio = new Date().toLocaleString();
      })
      .catch(error => {
          console.error('API request error:', error);
          throw error;
      });
    },

    getPerformances() {
      const queryParams = new URLSearchParams({ startDate: this.start_date, endDate: this.start_date }).toString();
      fetch(`/api/strats/performance?${queryParams}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin'
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((res) => { 
        this.performances = Object.fromEntries(
          Object.entries(res).map(([key, value]) => [String(key), value])
        );
      })
      .catch(error => {
          console.error('API request error:', error);
          throw error;
      });
    },

    handleAccountIdUpdate(newAccountId) {
      this.selected_account_id = newAccountId;
    },

    setDefaultStartDate() {
      const recentWeekday = this.getRecentWeekdayPST();
      this.start_date = this.formatDateForAPI(recentWeekday);
    },

    getRecentWeekdayPST() {
      // Create a date in Los Angeles timezone
      const laDateString = new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" });
      const pstDate = new Date(laDateString);

      const day = pstDate.getDay();
      // If Sunday (0), go back to Friday (-2 days)
      if (day === 0) { 
        pstDate.setDate(pstDate.getDate() - 2);
      } else if (day === 6) { // If Saturday (6), go back to Friday (-1 day)
        pstDate.setDate(pstDate.getDate() - 1);
      }

      return pstDate;
    },

    formatDateForAPI(date) {
      const mm = String(date.getMonth() + 1).padStart(2, '0');
      const dd = String(date.getDate()).padStart(2, '0');
      const yyyy = date.getFullYear();
      return `${mm}${dd}${yyyy}`; // MMDDYYYY format
    },
  }
};
</script>

<style scoped>
.main {
  display: flex;
}

.main > div {
  flex: 1;
  max-width: 50%;
}

.portfolio-container {
  display: flex;
  margin-bottom: 50px;
}

.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cards-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 50px;
  flex-wrap: wrap;
}
.main-container {
  padding-left: 50px;
  padding-right: 50px;
  font-family: 'Montserrat', Arial, sans-serif;
}
</style>
