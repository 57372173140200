<template>
  <div class="card">
    <div class="header-row">
      <div class="title">{{ strat.title }}</div>
      <div class="quantity-box">
        <span class="text">Quantity:</span>
        <input type="number"
               v-model="localQuantity"
               class="quantity-input"
               @blur="onQuantityBlur">
      </div>
    </div>
    <div class="description">{{ strat.desc }}</div>
    <div class="card-body">
      <div class="symbols">
        <div class="symbol-box" v-for="symbol in symbols" :key="symbol">
          <label class="switch">
            <!-- Bind checked state to a computed boolean or directly assume true if it's in symbols -->
            <input type="checkbox"
                   :checked="true"
                   @change="onCheckboxChange($event, symbol)">
            <span class="slider round"></span>
          </label>
          <span class="text">{{ symbol }}</span>
        </div>
      </div>
      <SymbolInput @add="handleAddSymbol" />
    </div>
    <button class="kill-button" @click="kill">Kill</button>
  </div>
</template>

<script>
import SymbolInput from '../common/SymbolInput.vue';

export default {
  name: 'ActiveStrat',
  components: { SymbolInput },
  props: {
    strat: {
      required: true
    }
  },
  data() {
    return {
      account_id: this.strat.account_ids[0],
      alerts: {},
      localQuantity: this.strat.quantity
    };
  },

  computed: {
    symbols() {
      return this.strat.symbols.sort();
    }
  },

  methods: {
    async kill() {
      const options = {
        method: 'POST',
        body: JSON.stringify({ account_id: this.account_id, id: this.strat.id }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin'
      };
      try {
        await fetch('/api/strats/kill', options);
      } catch (error) {
        console.error('API request error:', error);
      }
      this.$emit('update');
    },

    async onCheckboxChange(event, symbol) {
      if (!event.target.checked) {
        // Checkbox was unchecked, remove symbol
        await this.removeSymbol(symbol);
        this.$emit('update');
      }
      // If it was checked, do nothing because all displayed symbols are considered active.
    },

    async handleAddSymbol(symbol) {
      await this.addSymbol(symbol);
      this.$emit('update');
    },

    async updateQuantity(quantity) {
      const options = {
        method: 'POST',
        body: JSON.stringify({ account_id: this.account_id, quantity }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin'
      };
      try {
        await fetch('/api/strats/quantity', options);
        this.$emit('update');
      } catch (error) {
        console.error('API request error:', error);
      }
    },

    onQuantityBlur() {
      const qty = parseInt(this.localQuantity, 10);
      if (isNaN(qty) || qty < 0) {
        this.localQuantity = this.strat.quantity;
        return;
      }
      if (qty !== this.strat.quantity) {
        this.updateQuantity(qty);
      }
    },

    async addSymbol(symbol) {
      const options = {
        method: 'POST',
        body: JSON.stringify({ account_id: this.account_id, symbol }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin'
      };
      try {
        await fetch('/api/strats/add-symbol', options);
      } catch (error) {
        console.error('API request error:', error);
      }
    },

    async removeSymbol(symbol) {
      const options = {
        method: 'POST',
        body: JSON.stringify({ account_id: this.account_id, symbol }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin'
      };
      try {
        await fetch('/api/strats/remove-symbol', options);
      } catch (error) {
        console.error('API request error:', error);
      }
    }
  }
};
</script>

<style scoped>
.card {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 475px;
  flex-shrink: 0;
}

.title {
  font-size: 20px;
  color: #333;
  display: flex;
  align-items: center;
  height: 100%;
}

.description {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.header-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

.symbols {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.quantity-box {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.symbol-box {
  width: 33%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: left;
}
.text {
  margin-left: 10px;
}

  /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #76EE59;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.quantity-input {
  width: 40px;
  margin-left: 10px;
  padding: 12px 16px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -webkit-appearance: none; 
  -moz-appearance: none;    
  appearance: none;        
  font-family: 'Montserrat', sans-serif;
}

.kill-button {
  cursor: pointer;
  margin-top: 20px;
  padding: 12px 16px;
  font-size: 16px;
  color: white;
  background-color: #D22B2B;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -webkit-appearance: none; /* Removes default styling on iOS */
  -moz-appearance: none;    /* Removes default styling on Firefox */
  appearance: none;         /* Removes default styling */
  font-family: 'Montserrat', sans-serif;
}

</style>
