<template>
  <div class="container">
    <h2>Live Portfolio</h2>
    <p>Last updated: {{ last_updated }}</p>
    <table border="1" v-if="triplets.length > 0">
      <thead>
        <tr>
          <th>Symbol</th>
          <th>Quantity</th>
          <th>Symbol</th>
          <th>Quantity</th>
          <th>Symbol</th>
          <th>Quantity</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in triplets" :key="rowIndex">
          <td>{{ row[0] ? row[0][0] : '' }}</td>
          <td>{{ row[0] ? row[0][1] : '' }}</td>
          <td>{{ row[1] ? row[1][0] : '' }}</td>
          <td>{{ row[1] ? row[1][1] : '' }}</td>
          <td>{{ row[2] ? row[2][0] : '' }}</td>
          <td>{{ row[2] ? row[2][1] : '' }}</td>
        </tr>
      </tbody>
    </table>
    <p v-else>No open positions.</p>
  </div>
</template>

<script>
export default {
  name: 'Portfolio',
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    last_updated: {
      type: String,
      required: false,
      default: ''
    }
  },

  computed: {
    triplets() {
      // Convert the portfolio object into an array of [symbol, quantity] pairs
      const entries = Object.entries(this.portfolio); 
      
      // We want three (symbol, quantity) pairs per row, so chunk by 3
      const rows = [];
      for (let i = 0; i < entries.length; i += 3) {
        const row = entries.slice(i, i + 3);
        // row might have one, two, or three elements
        rows.push(row);
      }
      return rows; 
    }
  }
};
</script>

<style scoped>
.container {
  width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: right;
}

th:first-child,
td:first-child {
  text-align: left;
  width: auto; 
}
</style>
